.MuiAlert-message {
    display: flex !important;
    align-items: center !important;
    font-size: 12px !important;
}

.unitValue {
    justify-content: flex-start;
    margin-bottom: 3rem;
    margin-top: -1rem;
}