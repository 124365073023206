.skipContentLinkEW {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.skipContentLinkEW:focus {
  clip: auto;
  height: auto;
  position: fixed;
  z-index: 1001;
  background: #ffffff;
  padding: 13px 0px 5px 10%;
  box-sizing: unset;
  left: 0;
  text-align: left;
  top: 0;
  width: 97%;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-decoration: none;
}


.skipContentLinkEW p {
  display: flex;
  font-weight: bold;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
