@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/AvenirLTStd-Roman.otf');
}

@font-face {
  font-family: 'Avenir';
  font-style: bold;
  font-weight: 900;
  src: url('./assets/fonts/AvenirLTStd-Black.otf');
}

@font-face {
  font-family: 'Avenir';
  font-style: lighter;
  font-weight: 300;
  src: url('./assets/fonts/AvenirLTStd-Book.otf');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Proxima_Nova_Medium.otf');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: bold;
  font-weight: 700;
  src: url('./assets/fonts/Proxima_Nova_Bold.otf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: lighter;
  font-weight: 300;
  src: url('./assets/fonts/Proxima_Nova_Light.otf');
}

html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size-adjust: 100%;
}

body {
  margin: 0;
  font-family: 'Avenir', 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

p {
  font-size: 16px;
  font-family: 'Avenir', sans-serif;
  color: #333333
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grecaptcha-badge{
  display: none !important;
}
