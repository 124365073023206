.recaptcha {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.recaptcha svg {
  width: 34px;
  margin-right: 10px;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltipText {
  visibility:hidden;
  color: rgb(5, 5, 5);
  /* Position the tooltip text */
  position: absolute;
  bottom: 150%;
  margin-left: -50px;
  border-radius: 2px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  padding: 0.5rem 1rem;
  background-color: #fff;
  font-size: 1rem;
  line-height: 1.1rem;
  border: 1px solid #959596;
  overflow-y: auto;
  width: 140px;
}
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}