.summaryValue {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bullet {
    color: #da3732;
    font-weight: bold;
    font-size: 16px;
}

ul {
    padding: 0 0 0 16px;
    margin: 0;
    float: left;
}
